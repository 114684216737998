var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var wW,xW,Rla,zW,Sla,Tla,Ula,AW,yW;$CLJS.vW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};wW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.PH(b,$CLJS.PI)?$CLJS.KV:$CLJS.PH(b,$CLJS.YK)?$CLJS.oL:$CLJS.PH(b,$CLJS.TJ)?$CLJS.pL:null;return $CLJS.m(b)?$CLJS.qV(b,a):!0};
xW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.PH($CLJS.EL(d),$CLJS.mJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.EL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(wW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Rla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.ev,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.yt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,yW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,yW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.gv,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.kl);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(xW(b))].join("")}],null),$CLJS.$f(xW)],null)],null)};
zW=function(a){var b=$CLJS.T,c=Rla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.ev,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.wk,c,a],null)};Sla=function(a){return $CLJS.Ve($CLJS.nV,$CLJS.rg.h(function(b){var c=$CLJS.EL(b),d=$CLJS.PH(c,$CLJS.NL);b=d?$CLJS.qV($CLJS.EV,b):d;return $CLJS.m(b)?$CLJS.wl:c},a))};Tla=function(a){a=$CLJS.vW(function(b){return!$CLJS.PH(b,$CLJS.mJ)},$CLJS.rg.h($CLJS.EL,a));return $CLJS.PH(a,$CLJS.NL)?$CLJS.Dl:a};Ula=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.PH($CLJS.EL(b),$CLJS.mJ)},a))?Tla(a):Sla(a)};
AW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.BW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);yW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.kL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.cm,yW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.EL(a);return $CLJS.Wf(function(d){return wW(d,c)},b)}],null)],null));
$CLJS.kL(AW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)],null));$CLJS.vV($CLJS.cm,zW($CLJS.cm));$CLJS.vV($CLJS.yu,zW($CLJS.yu));$CLJS.BV($CLJS.dm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,AW],null)]));$CLJS.BV($CLJS.QK,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,AW],null)]));
for(var CW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cm,$CLJS.yu,$CLJS.dm],null)),DW=null,EW=0,FW=0;;)if(FW<EW){var Vla=DW.$(null,FW);$CLJS.oV(Vla,$CLJS.BW);FW+=1}else{var GW=$CLJS.A(CW);if(GW){var HW=GW;if($CLJS.re(HW)){var IW=$CLJS.$c(HW),Wla=$CLJS.ad(HW),Xla=IW,Yla=$CLJS.E(IW);CW=Wla;DW=Xla;EW=Yla}else{var Zla=$CLJS.C(HW);$CLJS.oV(Zla,$CLJS.BW);CW=$CLJS.D(HW);DW=null;EW=0}FW=0}else break}$CLJS.DL.o(null,$CLJS.BW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Ula(a)});
$CLJS.zV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.oV($CLJS.uI,$CLJS.QL);
for(var JW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,$CLJS.$H,$CLJS.pJ],null)),KW=null,LW=0,MW=0;;)if(MW<LW){var $la=KW.$(null,MW);$CLJS.zV($la,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));MW+=1}else{var NW=$CLJS.A(JW);if(NW){var OW=NW;if($CLJS.re(OW)){var PW=$CLJS.$c(OW),ama=$CLJS.ad(OW),bma=PW,cma=$CLJS.E(PW);JW=ama;KW=bma;LW=cma}else{var dma=$CLJS.C(OW);$CLJS.zV(dma,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));JW=$CLJS.D(OW);KW=null;LW=0}MW=0}else break}
for(var QW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kI,$CLJS.HJ,$CLJS.WJ],null)),RW=null,SW=0,TW=0;;)if(TW<SW){var ema=RW.$(null,TW);$CLJS.zV(ema,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));TW+=1}else{var UW=$CLJS.A(QW);if(UW){var VW=UW;if($CLJS.re(VW)){var WW=$CLJS.$c(VW),fma=$CLJS.ad(VW),gma=WW,hma=$CLJS.E(WW);QW=fma;RW=gma;SW=hma}else{var ima=$CLJS.C(VW);$CLJS.zV(ima,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));QW=$CLJS.D(VW);RW=null;SW=0}TW=0}else break}$CLJS.zV($CLJS.nI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));
$CLJS.DL.o(null,$CLJS.nI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.PH($CLJS.EL(b),$CLJS.cl)&&$CLJS.PH($CLJS.EL(a),$CLJS.cl)?$CLJS.cl:$CLJS.LK});