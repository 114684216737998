var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var Tma;$CLJS.iY=new $CLJS.N("mbql.filter","operator","mbql.filter/operator",-1267834132);Tma=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);for(var jY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.wk],null)),kY=null,lY=0,mY=0;;)if(mY<lY){var Uma=kY.$(null,mY);$CLJS.BV(Uma,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null)],null)],null)]));mY+=1}else{var nY=$CLJS.A(jY);if(nY){var oY=nY;if($CLJS.re(oY)){var pY=$CLJS.$c(oY),Vma=$CLJS.ad(oY),
Wma=pY,Xma=$CLJS.E(pY);jY=Vma;kY=Wma;lY=Xma}else{var Yma=$CLJS.C(oY);$CLJS.BV(Yma,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null)],null)],null)]));jY=$CLJS.D(oY);kY=null;lY=0}mY=0}else break}$CLJS.zV($CLJS.Nk,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)]));
for(var qY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.DK],null)),rY=null,sY=0,tY=0;;)if(tY<sY){var Zma=rY.$(null,tY);$CLJS.BV(Zma,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)],null)],null)]));tY+=1}else{var uY=$CLJS.A(qY);if(uY){var vY=uY;if($CLJS.re(vY)){var wY=$CLJS.$c(vY),$ma=$CLJS.ad(vY),
ana=wY,bna=$CLJS.E(wY);qY=$ma;rY=ana;sY=bna}else{var cna=$CLJS.C(vY);$CLJS.BV(cna,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.el,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.lk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)],null)],null)],null)]));qY=$CLJS.D(vY);rY=null;sY=0}tY=0}else break}
for(var xY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Zl,$CLJS.km,$CLJS.am,$CLJS.jm],null)),yY=null,zY=0,AY=0;;)if(AY<zY){var dna=yY.$(null,AY);$CLJS.zV(dna,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)]));AY+=1}else{var BY=$CLJS.A(xY);if(BY){var CY=BY;if($CLJS.re(CY)){var DY=$CLJS.$c(CY),ena=$CLJS.ad(CY),fna=DY,gna=$CLJS.E(DY);xY=ena;yY=fna;zY=gna}else{var hna=$CLJS.C(CY);$CLJS.zV(hna,$CLJS.G([$CLJS.yu,$CLJS.sl,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)]));xY=$CLJS.D(CY);yY=null;zY=0}AY=0}else break}$CLJS.zV($CLJS.KI,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)]));
$CLJS.zV($CLJS.VI,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)]));
for(var EY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yI,$CLJS.oJ],null)),FY=null,GY=0,HY=0;;)if(HY<GY){var ina=FY.$(null,HY);$CLJS.zV(ina,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null)]));HY+=1}else{var IY=$CLJS.A(EY);if(IY){var JY=IY;if($CLJS.re(JY)){var KY=$CLJS.$c(JY),jna=$CLJS.ad(JY),kna=KY,lna=$CLJS.E(KY);EY=jna;FY=kna;GY=lna}else{var mna=$CLJS.C(JY);$CLJS.zV(mna,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null)]));EY=$CLJS.D(JY);
FY=null;GY=0}HY=0}else break}
for(var LY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,$CLJS.aJ],null)),MY=null,NY=0,OY=0;;)if(OY<NY){var nna=MY.$(null,OY);$CLJS.zV(nna,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null)]));OY+=1}else{var PY=$CLJS.A(LY);if(PY){var QY=PY;if($CLJS.re(QY)){var RY=$CLJS.$c(QY),ona=$CLJS.ad(QY),pna=RY,qna=$CLJS.E(RY);LY=ona;MY=pna;NY=qna}else{var rna=$CLJS.C(QY);$CLJS.zV(rna,$CLJS.G([$CLJS.yu,$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null)]));LY=$CLJS.D(QY);
MY=null;NY=0}OY=0}else break}
for(var SY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aK,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null)],null),TY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vJ,$CLJS.nK,$CLJS.LJ,$CLJS.wJ],null)),UY=null,VY=0,WY=0;;)if(WY<VY){var XY=UY.$(null,WY);$CLJS.wV(XY,$CLJS.sl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Mt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,XY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,$CLJS.zL,SY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null)],null));WY+=1}else{var YY=$CLJS.A(TY);if(YY){var ZY=YY;if($CLJS.re(ZY)){var $Y=$CLJS.$c(ZY),sna=$CLJS.ad(ZY),tna=$Y,una=$CLJS.E($Y);TY=sna;UY=tna;VY=una}else{var aZ=$CLJS.C(ZY);$CLJS.wV(aZ,$CLJS.sl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Mt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,aZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,$CLJS.zL,SY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],
null)],null));TY=$CLJS.D(ZY);UY=null;VY=0}WY=0}else break}
$CLJS.wV($CLJS.rK,$CLJS.sl,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.Mt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.rK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,$CLJS.zL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yK,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ht,$CLJS.oB,$CLJS.qD,$CLJS.SI],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.gq,$CLJS.UL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.pL],null)],null));$CLJS.wV($CLJS.oI,$CLJS.sl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Mt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.oI],null),$CLJS.zL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,$CLJS.tL,$CLJS.vL],null)],null));
$CLJS.kL(Tma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.iY],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DV,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Ht,$CLJS.Yl,$CLJS.DK,$CLJS.VI,$CLJS.KI,$CLJS.Zl,$CLJS.am,$CLJS.km,$CLJS.jm,$CLJS.yI,$CLJS.oJ,$CLJS.LI,$CLJS.aJ,$CLJS.LJ,$CLJS.wJ,$CLJS.vJ,$CLJS.nK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UI,$CLJS.Gl],null)],null));