var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var o0,p0,q0,n0,r0,Ooa,Poa,Qoa,w0,x0,z0,A0,B0,C0,D0,E0,Soa,Toa,G0,Uoa,H0,Voa,J0,Woa,Xoa,Yoa,Zoa,$oa;o0=function(a){var b=n0;return $CLJS.aG(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};p0=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.ik,a],null));};
q0=function(a){var b=$CLJS.ne(a);return b?(b=!$CLJS.Ig(a))?$CLJS.Em.h($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b};n0=function(a){return $CLJS.m($CLJS.Em.h($CLJS.pf,$CLJS.Jb)(a))?$CLJS.H_(a):a};r0=function(a,b){var c=q0(b);return $CLJS.m(c)?(b=n0($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c};
Ooa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.cn,$CLJS.Ye,$CLJS.Qk,n0,$CLJS.dJ,n0],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=s0.h?s0.h(b,t0):s0.call(null,b,t0);return d.call(c,b,a)})};
Poa=function(a){a=$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=n0(c);var d=Ooa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Qk.g(a),$CLJS.dI)&&$CLJS.Gb($CLJS.dJ.g(a))?$CLJS.U.j(a,$CLJS.dJ,$CLJS.GR):a};
Qoa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.e_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(Poa(b),$CLJS.V,c)],null)}),a)};w0=function(a){return $CLJS.u0.j($CLJS.u0.j($CLJS.Sb(function(b,c){return $CLJS.u0.j(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.uJ,$CLJS.cga,$CLJS.EP,Roa,$CLJS.tF,$CLJS.dn],null)),$CLJS.LG,$CLJS.Cm.h(v0,s0)),$CLJS.gP,$CLJS.XZ)};
x0=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,a,null],null):a};z0=function(a){return y0.g(x0(a))};A0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.g_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(y0,b)))};B0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,z0(b)],null),$CLJS.rg.h(y0,c))};
C0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,z0(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)};D0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,z0(a)],null)};E0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,y0.g(a)],null)};Soa=function(a){return $CLJS.Mg($CLJS.eg(v0,F0(a)))};
Toa=function(a){for(;;)if($CLJS.m(q0(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Gm.h(x0,a))};G0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.dI);return $CLJS.m(b)?$CLJS.Hm.j(a,$CLJS.dI,y0):a};
Uoa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,G0(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[l,G0(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};H0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.kR);return $CLJS.m(b)?$CLJS.Hm.j(a,$CLJS.kR,Uoa):a};Voa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.WQ);b=$CLJS.Gb(a)?I0.g?I0.g(b):I0.call(null,b):b;return $CLJS.m(a)?H0(b):b};J0=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a};
Woa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.xO);var c=$CLJS.M.h(b,$CLJS.QO),d=$CLJS.M.h(b,$CLJS.QP),e=$CLJS.M.h(b,$CLJS.WQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.QP);var l=$CLJS.Qu($CLJS.um.h(f,$CLJS.QP),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xO,$CLJS.QP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Hm.j(l,$CLJS.xO,I0):l,q=$CLJS.m(c)?$CLJS.Hm.j(n,$CLJS.QO,$CLJS.bg($CLJS.Gm,v0)):n,u=$CLJS.m(e)?$CLJS.Hm.j(q,$CLJS.WQ,H0):q,v=$CLJS.Qf(u),x=$CLJS.M.h(v,$CLJS.WQ);return $CLJS.m(x)?
$CLJS.U.j(v0($CLJS.um.h(v,$CLJS.WQ)),$CLJS.WQ,x):v0(v)}catch(y){if(y instanceof Error)throw n=y,$CLJS.yj($CLJS.VH("Error canonicalizing query: {0}",$CLJS.G([$CLJS.TZ(n)])),new $CLJS.k(null,1,[$CLJS.xO,a],null),n);throw y;}};
Xoa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.xO),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.IP);c=$CLJS.M.h(c,$CLJS.zO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.si($CLJS.Ve($CLJS.qg,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.GQ)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,n,$CLJS.um.h(q,$CLJS.SQ)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.sZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b))))),$CLJS.K0.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xO,$CLJS.zO],null),$CLJS.Cm.h($CLJS.Mg,$CLJS.bg($CLJS.Fm,b)))):a};
Yoa=function(a){try{return Xoa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.WH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.xO,a],null),b);}throw c;}};
Zoa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=L0.h?L0.h(B,I):L0.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return L0.h?L0.h(y,B):L0.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null};$oa=function(a,b){a=$CLJS.Gm.h(function(c){var d=$CLJS.kf.h(b,M0);return L0.h?L0.h(c,d):L0.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null};
$CLJS.K0=function K0(a){switch(arguments.length){case 3:return K0.j(arguments[0],arguments[1],arguments[2]);case 4:return K0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return K0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return K0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return K0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.K0.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.C(d);if(d=$CLJS.D(d))a=$CLJS.U.j(a,b,$CLJS.K0.j($CLJS.M.h(a,b),d,c));else{d=$CLJS.U.j;var e=$CLJS.M.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.U,a,b,c)}return a};
$CLJS.K0.D=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.C(e);if(e=$CLJS.D(e))a=$CLJS.U.j(a,b,$CLJS.K0.D($CLJS.M.h(a,b),e,c,d));else{e=$CLJS.U.j;var f=$CLJS.M.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.K0.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.C(f);if(f=$CLJS.D(f))a=$CLJS.U.j(a,b,$CLJS.K0.R($CLJS.M.h(a,b),f,c,d,e));else{f=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.K0.aa=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.C(g);if(g=$CLJS.D(g))a=$CLJS.U.j(a,b,$CLJS.K0.aa($CLJS.M.h(a,b),g,c,d,e,f));else{g=$CLJS.U.j;var l=$CLJS.M.h(a,b);c=c.D?c.D(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.K0.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.C(l);return(l=$CLJS.D(l))?$CLJS.U.j(a,b,$CLJS.R.l($CLJS.K0,$CLJS.M.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.M.h(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.K0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.K0.v=6;
$CLJS.u0=function u0(a){switch(arguments.length){case 3:return u0.j(arguments[0],arguments[1],arguments[2]);case 4:return u0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return u0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return u0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return u0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.u0.j=function(a,b,c){var d=$CLJS.Ke(a,b);if($CLJS.m(d)){var e=$CLJS.U.j;d=$CLJS.uc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.u0.D=function(a,b,c,d){var e=$CLJS.Ke(a,b);if($CLJS.m(e)){var f=$CLJS.U.j;e=$CLJS.uc(e);c=c.h?c.h(e,d):c.call(null,e,d);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.u0.R=function(a,b,c,d,e){var f=$CLJS.Ke(a,b);if($CLJS.m(f)){var g=$CLJS.U.j;f=$CLJS.uc(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.u0.aa=function(a,b,c,d,e,f){var g=$CLJS.Ke(a,b);if($CLJS.m(g)){var l=$CLJS.U.j;g=$CLJS.uc(g);c=c.D?c.D(g,d,e,f):c.call(null,g,d,e,f);a=l.call($CLJS.U,a,b,c)}return a};$CLJS.u0.l=function(a,b,c,d,e,f,g){var l=$CLJS.Ke(a,b);return $CLJS.m(l)?$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.uc(l),d,e,f,$CLJS.G([g]))):a};
$CLJS.u0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.u0.v=6;
var apa=new $CLJS.N(null,"value_field","value_field",-980977878),N0=new $CLJS.N(null,"ascending","ascending",-988350486),O0=new $CLJS.N(null,"named","named",-422393479),P0=new $CLJS.N(null,"values_source_config","values_source_config",-590570309),bpa=new $CLJS.N(null,"field-id","field-id",-353751335),Q0=new $CLJS.N(null,"descending","descending",-24766135),R0=new $CLJS.N(null,"binning-strategy","binning-strategy",2063329158),S0=new $CLJS.N(null,"datetime-field","datetime-field",21731696),cpa=new $CLJS.N(null,
"use-as-display-name?","use-as-display-name?",686752941),T0=new $CLJS.N(null,"field-literal","field-literal",-1295883554),U0=new $CLJS.N(null,"viz-settings","viz-settings",256055379),dpa=new $CLJS.N(null,"rows","rows",850049680),epa=new $CLJS.N(null,"special-fn","special-fn",1290649344),M0=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),t0=new $CLJS.N(null,"ignore-path","ignore-path",944069061),fpa=new $CLJS.N(null,"label_field","label_field",-1573182765),
gpa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),hpa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),Roa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var V0,ipa=$CLJS.cj.g($CLJS.P),jpa=$CLJS.cj.g($CLJS.P),kpa=$CLJS.cj.g($CLJS.P),lpa=$CLJS.cj.g($CLJS.P),mpa=$CLJS.M.j($CLJS.P,$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));V0=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Cm.h(n0,$CLJS.C),$CLJS.cn,mpa,ipa,jpa,kpa,lpa);V0.o(null,$CLJS.PP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PP,b],null);return null!=a?$CLJS.kf.h(b,a):b});
V0.o(null,$CLJS.BG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BG,a instanceof $CLJS.N?$CLJS.e_(a):a],null)});V0.o(null,R0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(V0.g(new $CLJS.S(null,3,5,$CLJS.T,[R0,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[R0,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=s0.h?s0.h(a,t0):s0.call(null,a,t0);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,b,function(){var d=$CLJS.m($CLJS.EG.g(c))?$CLJS.Hm.j(c,$CLJS.EG,$CLJS.Oi):c;d=$CLJS.m($CLJS.SQ.g(c))?$CLJS.Hm.j(d,$CLJS.SQ,$CLJS.Oi):d;return $CLJS.m($CLJS.RR.g(c))?$CLJS.Hm.j(d,$CLJS.RR,function(e){return $CLJS.m($CLJS.XK.g(e))?$CLJS.Hm.j(e,$CLJS.XK,$CLJS.Oi):e}):d}()],null)});
V0.o(null,T0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[T0,b instanceof $CLJS.N?$CLJS.e_(b):b,$CLJS.Oi.g(a)],null)});V0.o(null,S0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[S0,s0.h?s0.h(b,t0):s0.call(null,b,t0),$CLJS.xu,n0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[S0,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.rK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(V0.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rK,b,c,d],null)),s0.h?s0.h(a,t0):s0.call(null,a,t0)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rK,s0.h?s0.h(b,t0):s0.call(null,b,t0),$CLJS.Ae(c)?c:n0(c),n0(d)],null)});
V0.o(null,$CLJS.sJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sJ,b,n0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,$CLJS.oB],null)});V0.o(null,$CLJS.jJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jJ,b,n0(a)],null)});
V0.o(null,$CLJS.eK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.eK,s0.h?s0.h(b,t0):s0.call(null,b,t0),c,n0(a)],null)});V0.o(null,$CLJS.MK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.MK,s0.h?s0.h(b,t0):s0.call(null,b,t0),c,n0(a)],null)});
V0.o(null,$CLJS.dK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dK,s0.h?s0.h(b,t0):s0.call(null,b,t0)],null)});
V0.o(null,$CLJS.pK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.pK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c),n0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.iJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.iJ,s0.h?s0.h(b,t0):s0.call(null,b,t0),s0.h?s0.h(c,t0):s0.call(null,c,t0),n0(a)],null)});V0.o(null,$CLJS.kl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,b,a],null)});
V0.o(null,$CLJS.cn,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[n0(a)],null),$CLJS.rg.g(function(c){return s0.h?s0.h(c,t0):s0.call(null,c,t0)}),b)});
var W0=function W0(a){if($CLJS.m($CLJS.Em.h($CLJS.pf,$CLJS.Jb)(a))){var c=n0(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.lk,null,$CLJS.dm,null,$CLJS.AK,null,$CLJS.fK,null,$CLJS.yu,null,$CLJS.NJ,null,$CLJS.OK,null,$CLJS.QK,null,$CLJS.NI,null,$CLJS.nJ,null,$CLJS.cm,null,$CLJS.RI,null,$CLJS.vK,null,$CLJS.ZJ,null,$CLJS.Ql,null,$CLJS.mD,null,$CLJS.DJ,null,$CLJS.tK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(q0(a))?(a=$CLJS.C(a),W0.g?W0.g(a):W0.call(null,
a)):null},npa=new $CLJS.k(null,8,[$CLJS.Qk,n0,$CLJS.WQ,function(a){a=o0(a);return $CLJS.A($CLJS.kR.g(a))?$CLJS.Hm.j(a,$CLJS.kR,Qoa):a},$CLJS.xO,new $CLJS.k(null,6,[$CLJS.PP,function X0(a){if($CLJS.m($CLJS.Em.h($CLJS.pf,$CLJS.Jb)(a)))return n0(a);if($CLJS.m(r0(O0,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[O0,X0.g?X0.g(a):X0.call(null,a)],null),c)}return $CLJS.m($CLJS.m(q0(a))?W0($CLJS.Zd(a)):null)?$CLJS.Gm.h(X0,a):s0.h?s0.h(a,
t0):s0.call(null,a,t0)},$CLJS.RQ,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(u),s0.h?s0.h(q,t0):s0.call(null,q,t0)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.e_(l),s0.h?s0.h(f,t0):s0.call(null,f,t0)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.aS,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(q0(q))?V0.g(q):$CLJS.lf(V0.g($CLJS.lf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(q0(l))?V0.g(l):$CLJS.lf(V0.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.nR,function(a){a=o0(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.WQ);if($CLJS.m(b))return a=$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.WQ,$CLJS.xO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.WQ],null),a=s0.h?s0.h(a,b):s0.call(null,a,b),$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.xO,$CLJS.WQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.xO],null);return s0.h?s0.h(a,b):s0.call(null,a,b)},$CLJS.QP,new $CLJS.k(null,1,[M0,w0],null),$CLJS.XO,new $CLJS.k(null,1,[M0,function(a){a=s0.h?s0.h(a,$CLJS.xO):s0.call(null,a,$CLJS.xO);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.XK),d=$CLJS.M.h(b,$CLJS.zO);a=$CLJS.M.h(b,$CLJS.yP);b=$CLJS.m(c)?$CLJS.Hm.j(b,$CLJS.XK,n0):b;d=$CLJS.m($CLJS.Em.h($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Hm.j(b,$CLJS.zO,n0):b;return $CLJS.m(a)?$CLJS.Hm.j(d,$CLJS.yP,$CLJS.e_):d}],null)],null),
$CLJS.aE,new $CLJS.k(null,1,[$CLJS.qP,$CLJS.Ye],null),$CLJS.QO,new $CLJS.k(null,1,[M0,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Qk),d=$CLJS.M.h(b,$CLJS.aL),e=$CLJS.M.h(b,$CLJS.Bk);a=$CLJS.M.h(b,P0);b=$CLJS.m(e)?$CLJS.Hm.j(b,$CLJS.Bk,$CLJS.e_):b;c=$CLJS.m(c)?$CLJS.Hm.j(b,$CLJS.Qk,n0):b;d=$CLJS.m(d)?$CLJS.Hm.j(c,$CLJS.aL,function(f){return s0.h?s0.h(f,t0):s0.call(null,f,t0)}):c;d=$CLJS.m(a)?$CLJS.K0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[P0,fpa],null),function(f){return s0.h?s0.h(f,t0):s0.call(null,
f,t0)}):d;return $CLJS.m(a)?$CLJS.K0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[P0,apa],null),function(f){return s0.h?s0.h(f,t0):s0.call(null,f,t0)}):d}],null),$CLJS.OO,function(a){return null==a?null:n0(a)},$CLJS.QP,new $CLJS.k(null,1,[M0,w0],null),U0,n0],null),s0=function s0(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return s0.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
s0.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Ku(npa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=n0(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,s0.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=n0(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,s0.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(q0(a))?V0.g(a):$CLJS.ne(a)?$CLJS.Gm.h(function(e){return s0.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),M0)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.VH("Error normalizing form: {0}",$CLJS.G([$CLJS.TZ(d)])),new $CLJS.k(null,3,[$CLJS.sF,a,$CLJS.Sl,c,epa,b],null),d);}throw e;}};s0.v=1;s0.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var y0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(q0(f))?$CLJS.C(f):null},$CLJS.cn,e,a,b,c,d)}();y0.o(null,$CLJS.cn,function(a){return a});
y0.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(r0($CLJS.GQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return y0.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,c,$CLJS.Rf($CLJS.Xm.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,b,$CLJS.Rf(a)],null)});
y0.o(null,$CLJS.PP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PP,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PP,b,a],null)});y0.o(null,bpa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(q0(a))?y0.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,a,null],null)});
y0.o(null,T0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,b,new $CLJS.k(null,1,[$CLJS.EG,a],null)],null)});y0.o(null,gpa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=z0(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=z0(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,a,$CLJS.U.j(c,$CLJS.YO,b)],null)});
y0.o(null,hpa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=z0(a);return $CLJS.R.D($CLJS.r_,a,$CLJS.U,$CLJS.G([$CLJS.oQ,b]))});
y0.o(null,S0,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=z0(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.EG);return $CLJS.Gb(c)||$CLJS.JS.h(c,b)?$CLJS.R.D($CLJS.r_,a,$CLJS.U,$CLJS.G([$CLJS.SQ,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),y0.g(new $CLJS.S(null,3,5,$CLJS.T,[S0,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});y0.o(null,R0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=z0(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,b,$CLJS.U.j(e,$CLJS.RR,$CLJS.Xm.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.XK,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var Y0=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.wk,$CLJS.Nk],null)),Z0=null,$0=0,a1=0;;)if(a1<$0){var b1=Z0.$(null,a1);y0.o(null,b1,function(){return function(a){return A0(a)}}(Y0,Z0,$0,a1,b1));a1+=1}else{var c1=$CLJS.A(Y0);if(c1){var d1=c1;if($CLJS.re(d1)){var e1=$CLJS.$c(d1),opa=$CLJS.ad(d1),ppa=e1,qpa=$CLJS.E(e1);Y0=opa;Z0=ppa;$0=qpa}else{var f1=$CLJS.C(d1);y0.o(null,f1,function(){return function(a){return A0(a)}}(Y0,Z0,$0,a1,f1,d1,c1));Y0=$CLJS.D(d1);Z0=null;$0=0}a1=0}else break}
y0.o(null,$CLJS.VI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VI,z0(a),z0(b)],null),c)});y0.o(null,$CLJS.rK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=z0(a);a=$CLJS.m($CLJS.f_($CLJS.GQ,a))?$CLJS.r_.l(c,$CLJS.um,$CLJS.G([$CLJS.SQ])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rK,a],null),b)});
for(var g1=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.vJ,$CLJS.nK,$CLJS.LJ,$CLJS.wJ,$CLJS.Yl,$CLJS.DK,$CLJS.Zl,$CLJS.km,$CLJS.am,$CLJS.jm,$CLJS.LI,$CLJS.aJ,$CLJS.yI,$CLJS.oJ,$CLJS.KI],null)),h1=null,i1=0,j1=0;;)if(j1<i1){var k1=h1.$(null,j1);y0.o(null,k1,function(){return function(a){return B0(a)}}(g1,h1,i1,j1,k1));j1+=1}else{var l1=$CLJS.A(g1);if(l1){var m1=l1;if($CLJS.re(m1)){var n1=$CLJS.$c(m1),rpa=$CLJS.ad(m1),spa=n1,tpa=$CLJS.E(n1);g1=rpa;h1=spa;i1=tpa}else{var o1=$CLJS.C(m1);y0.o(null,o1,
function(){return function(a){return B0(a)}}(g1,h1,i1,j1,o1,m1,l1));g1=$CLJS.D(m1);h1=null;i1=0}j1=0}else break}y0.o(null,dpa,function(){return null});y0.o(null,$CLJS.qO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qO,y0.g(b),a],null)});
y0.o(null,O0,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=y0.g;var e=$CLJS.T;b=y0.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,cpa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.UI,a],null);return c.call(y0,new $CLJS.S(null,3,5,e,[$CLJS.qO,b,a],null))});
for(var p1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mD,$CLJS.NJ],null)),q1=null,r1=0,s1=0;;)if(s1<r1){var t1=q1.$(null,s1);y0.o(null,t1,function(){return function(a){return C0(a)}}(p1,q1,r1,s1,t1));s1+=1}else{var u1=$CLJS.A(p1);if(u1){var v1=u1;if($CLJS.re(v1)){var w1=$CLJS.$c(v1),upa=$CLJS.ad(v1),vpa=w1,wpa=$CLJS.E(w1);p1=upa;q1=vpa;r1=wpa}else{var x1=$CLJS.C(v1);y0.o(null,x1,function(){return function(a){return C0(a)}}(p1,q1,r1,s1,x1,v1,u1));p1=$CLJS.D(v1);q1=null;r1=0}s1=0}else break}
for(var y1=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.DJ,$CLJS.RK,$CLJS.NI,$CLJS.AK,$CLJS.tK,$CLJS.lk,$CLJS.Ql,$CLJS.vK,$CLJS.RI],null)),z1=null,A1=0,B1=0;;)if(B1<A1){var C1=z1.$(null,B1);y0.o(null,C1,function(){return function(a){return D0(a)}}(y1,z1,A1,B1,C1));B1+=1}else{var D1=$CLJS.A(y1);if(D1){var E1=D1;if($CLJS.re(E1)){var F1=$CLJS.$c(E1),xpa=$CLJS.ad(E1),ypa=F1,zpa=$CLJS.E(F1);y1=xpa;z1=ypa;A1=zpa}else{var G1=$CLJS.C(E1);y0.o(null,G1,function(){return function(a){return D0(a)}}(y1,z1,A1,B1,
G1,E1,D1));y1=$CLJS.D(E1);z1=null;A1=0}B1=0}else break}y0.o(null,$CLJS.nJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nJ,z0(b),a],null)});
for(var H1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZJ,$CLJS.fK],null)),I1=null,J1=0,K1=0;;)if(K1<J1){var L1=I1.$(null,K1);y0.o(null,L1,function(){return function(a){return E0(a)}}(H1,I1,J1,K1,L1));K1+=1}else{var M1=$CLJS.A(H1);if(M1){var N1=M1;if($CLJS.re(N1)){var O1=$CLJS.$c(N1),Apa=$CLJS.ad(N1),Bpa=O1,Cpa=$CLJS.E(O1);H1=Apa;I1=Bpa;J1=Cpa}else{var P1=$CLJS.C(N1);y0.o(null,P1,function(){return function(a){return E0(a)}}(H1,I1,J1,K1,P1,N1,M1));H1=$CLJS.D(N1);I1=null;J1=0}K1=0}else break}
y0.o(null,$CLJS.OK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OK,y0.g(b),y0.g(a)],null)});
y0.o(null,$CLJS.$I,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(y0.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$I,b],null)),s0.l(a,$CLJS.G([t0]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$I,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[y0.g(v),y0.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[y0.g(n),y0.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
y0.o(null,$CLJS.bI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bI,y0.g(a),$CLJS.F.h(0,b)?1:y0.g(b)],null),$CLJS.rg.h(y0,c))});
var v0=function v0(a){if($CLJS.pe(a))return $CLJS.Xe(function(d,e,f){return $CLJS.U.j(d,e,v0.g?v0.g(f):v0.call(null,f))},a,a);if($CLJS.oe(a))return $CLJS.Lu(a,v0);if($CLJS.m(q0(a))){var c=function(){try{return y0.g(a)}catch(g){if(g instanceof Error){var d=g,e=$CLJS.ZF($CLJS.Xu);if($CLJS.m($CLJS.fG("metabase.mbql.normalize",e))){var f=$CLJS.WH("Invalid clause:");f instanceof Error?$CLJS.gG("metabase.mbql.normalize",e,$CLJS.WB.l($CLJS.G([a])),f):$CLJS.gG("metabase.mbql.normalize",e,$CLJS.WB.l($CLJS.G([f,
a])),null)}throw $CLJS.yj($CLJS.VH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.TZ(d)])),new $CLJS.k(null,1,[$CLJS.lS,a],null),d);}throw g;}}();return $CLJS.A(c)?$CLJS.oh.j($CLJS.kf.h($CLJS.ae(c),$CLJS.C(c)),$CLJS.rg.g(v0),$CLJS.zd(c)):c}return $CLJS.we(a)?$CLJS.Gm.h(v0,a):$CLJS.ke(a)?$CLJS.oh.j($CLJS.ae(a),$CLJS.rg.g(v0),a):a},F0=function F0(a){return function f(d,e){try{if($CLJS.m(function(){var x=p0($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=p0($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Mm.j(e,0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.Dm.h($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.dm,null,$CLJS.yu,null,$CLJS.QK,null,$CLJS.cm,
null,O0,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(W0(n)))return $CLJS.Mg($CLJS.Ve($CLJS.qg,$CLJS.rg.h(F0,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&
1<=$CLJS.E(e))try{var u=$CLJS.Mm.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;
else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},Q1=function Q1(a){return function f(d,e){try{var g=p0($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.GJ)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);
if($CLJS.O(n,$CLJS.tJ))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,N0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,Q0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=
Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,N0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,Q0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.GJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,z0(q)],null);throw $CLJS.Z;
}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.tJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,z0(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Mm.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.Mm.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.Zm.g($CLJS.rg.h(Q1,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.d_(f,d,e);
throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},I0=$CLJS.Cm.h(v0,function(a){var b=J0($CLJS.PP.g(a))?$CLJS.Hm.j(a,$CLJS.PP,Soa):a;b=J0($CLJS.IP.g(a))?$CLJS.Hm.j(b,$CLJS.IP,Toa):b;b=J0($CLJS.zO.g(a))?$CLJS.Hm.j(b,$CLJS.zO,$CLJS.bg($CLJS.Gm,x0)):b;b=J0($CLJS.aS.g(a))?$CLJS.Hm.j(b,$CLJS.aS,Q1):b;return J0($CLJS.nR.g(a))?$CLJS.Hm.j(b,$CLJS.nR,Voa):b}),Dpa=new $CLJS.k(null,3,[$CLJS.WQ,$CLJS.Ye,$CLJS.xO,new $CLJS.k(null,2,[$CLJS.nR,function(a){a=$CLJS.Qf(a);var b=
$CLJS.M.h(a,$CLJS.WQ);if($CLJS.m(b))return a=$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.WQ,$CLJS.xO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.WQ],null),a=L0.h?L0.h(a,b):L0.call(null,a,b),$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.xO,$CLJS.WQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.xO],null);return L0.h?L0.h(a,b):L0.call(null,a,b)},$CLJS.XO,new $CLJS.k(null,1,[M0,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.xO],null);return L0.h?L0.h(a,b):L0.call(null,a,b)}],null)],null),U0,$CLJS.Ye],null),
L0=function L0(a){switch(arguments.length){case 1:return L0.g(arguments[0]);case 2:return L0.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};L0.g=function(a){return L0.h(a,$CLJS.Lg)};
L0.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Ku(Dpa,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?Zoa(a,b):$CLJS.ne(a)?$oa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.sF,a,$CLJS.Sl,b],null),d);throw d;}};L0.v=2;
$CLJS.R1=function(){var a=$CLJS.Cm.l(L0,Yoa,Woa,$CLJS.G([s0]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.VH("Error normalizing query: {0}",$CLJS.G([$CLJS.TZ(c)])),new $CLJS.k(null,1,[$CLJS.xO,b],null),c);}throw d;}}}();