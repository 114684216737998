var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var R5,S5,T5,U5;R5=function(a,b,c){var d=$CLJS.d3(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.PP);d=$CLJS.vW($CLJS.Cm.j($CLJS.pi([c]),$CLJS.JG,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.VH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Vt,c,$CLJS.xO,a,$CLJS.h0,b],null));return d};S5=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
T5=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);U5=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.Dra=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.M3.o(null,$CLJS.PP,function(a,b){var c=$CLJS.Rf($CLJS.PP.g($CLJS.d3(a,b)));return $CLJS.m(c)?$CLJS.O4($CLJS.WH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.m3.D(a,b,v,$CLJS.n3);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.m3.D(a,
b,q,$CLJS.n3),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.k3.o(null,$CLJS.PP,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.EG);e=$CLJS.M.h(e,$CLJS.hk);c=$CLJS.J(c,2,null);c=R5(a,b,c);return $CLJS.Xm.l($CLJS.G([$CLJS.l3(a,b,c),new $CLJS.k(null,2,[$CLJS.e0,$CLJS.$2,$CLJS.H3,$CLJS.JG.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.EG,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.hk,e],null):null]))});
$CLJS.J3.o(null,$CLJS.PP,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.m3.D(a,b,R5(a,b,c),d)});$CLJS.oV(T5,U5);
for(var V5=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mD,$CLJS.NJ],null)),W5=null,X5=0,Y5=0;;)if(Y5<X5){var Era=W5.$(null,Y5);$CLJS.oV(Era,T5);Y5+=1}else{var Z5=$CLJS.A(V5);if(Z5){var $5=Z5;if($CLJS.re($5)){var a6=$CLJS.$c($5),Fra=$CLJS.ad($5),Gra=a6,Hra=$CLJS.E(a6);V5=Fra;W5=Gra;X5=Hra}else{var Ira=$CLJS.C($5);$CLJS.oV(Ira,T5);V5=$CLJS.D($5);W5=null;X5=0}Y5=0}else break}
$CLJS.J3.o(null,T5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.m3.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.VH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.VH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.WH("Count");case "cum-count":return $CLJS.WH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.K3.o(null,T5,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.k3.o(null,T5,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.sj($CLJS.k3,U5);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Cl,$CLJS.TI)});$CLJS.J3.o(null,$CLJS.$I,function(){return $CLJS.WH("Case")});
$CLJS.K3.o(null,$CLJS.$I,function(){return"case"});$CLJS.oV(S5,U5);for(var b6=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.DJ,$CLJS.RK,$CLJS.NI,$CLJS.Ql,$CLJS.vK,$CLJS.lk,$CLJS.AK,$CLJS.tK,$CLJS.RI],null)),c6=null,d6=0,e6=0;;)if(e6<d6){var Jra=c6.$(null,e6);$CLJS.oV(Jra,S5);e6+=1}else{var f6=$CLJS.A(b6);if(f6){var g6=f6;if($CLJS.re(g6)){var h6=$CLJS.$c(g6),Kra=$CLJS.ad(g6),Lra=h6,Mra=$CLJS.E(h6);b6=Kra;c6=Lra;d6=Mra}else{var Nra=$CLJS.C(g6);$CLJS.oV(Nra,S5);b6=$CLJS.D(g6);c6=null;d6=0}e6=0}else break}
$CLJS.K3.o(null,S5,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.J3.o(null,S5,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.m3.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.VH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.VH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.VH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.VH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.VH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.VH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.VH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.VH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.VH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.J3.o(null,$CLJS.nJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.VH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.m3.D(a,b,e,d)]))});$CLJS.K3.o(null,$CLJS.nJ,function(){return"percentile"});
$CLJS.oV($CLJS.nJ,U5);$CLJS.J3.o(null,$CLJS.OK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.VH("Sum of {0} matching condition",$CLJS.G([$CLJS.m3.D(a,b,e,d)]))});$CLJS.K3.o(null,$CLJS.OK,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.L3.j(a,b,d))].join("")});$CLJS.oV($CLJS.OK,U5);$CLJS.J3.o(null,$CLJS.ZJ,function(){return $CLJS.WH("Share of rows matching condition")});
$CLJS.K3.o(null,$CLJS.ZJ,function(){return"share"});$CLJS.oV($CLJS.ZJ,U5);$CLJS.J3.o(null,$CLJS.fK,function(){return $CLJS.WH("Count of rows matching condition")});$CLJS.K3.o(null,$CLJS.fK,function(){return"count-where"});$CLJS.oV($CLJS.fK,U5);
$CLJS.k3.o(null,U5,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.Xm.l;d=$CLJS.m(d)?$CLJS.li($CLJS.l3(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.oR],null)):null;var f=$CLJS.sj($CLJS.k3,$CLJS.cn);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Xm,$CLJS.G([d,a]))});$CLJS.cW.o(null,$CLJS.PP,function(a){return a});$CLJS.J3.o(null,$CLJS.nW,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.qW);return $CLJS.UI.g(a.A?a.A():a.call(null))});
$CLJS.P3.o(null,$CLJS.nW,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.DV);b=$CLJS.M.h(d,$CLJS.qW);c=$CLJS.M.h(d,$CLJS.oW);d=$CLJS.M.h(d,$CLJS.V2);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.G3,$CLJS.kG(a),$CLJS.G([$CLJS.F3,c]));return null!=d?$CLJS.U.j(a,$CLJS.u3,d):a});