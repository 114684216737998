var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var ela,fla,gla,hla,ila,jla,kla;$CLJS.FV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);ela=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);fla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.GV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.HV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);gla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
hla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);ila=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);jla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.IV=new $CLJS.N("type","temporal-bucketing-option","type/temporal-bucketing-option",1870188464);$CLJS.JV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.KV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
kla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var LV=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gn,$CLJS.jn,$CLJS.ml,$CLJS.Tj,$CLJS.kn,$CLJS.fn,$CLJS.Jj],null),MV=$CLJS.si(LV),lla,nla,VV;$CLJS.kL(ila,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid date extraction unit"],null)],null),MV));var NV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.yz,$CLJS.Sk,$CLJS.dl,$CLJS.Sj,$CLJS.Jj],null),OV=$CLJS.si(NV);
$CLJS.kL(hla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid date truncation unit"],null)],null),OV));$CLJS.PV=$CLJS.oh.j($CLJS.Lg,$CLJS.Zm.A(),$CLJS.qg.h(NV,LV));lla=$CLJS.si($CLJS.PV);$CLJS.kL($CLJS.HV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid date bucketing unit"],null)],null),lla));var QV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hn,$CLJS.en],null),RV=$CLJS.si(QV);
$CLJS.kL(gla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid time extraction unit"],null)],null),RV));var SV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.uz,$CLJS.tz,$CLJS.vz,$CLJS.xz],null),TV=$CLJS.si(SV);$CLJS.kL(kla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid time truncation unit"],null)],null),TV));
$CLJS.mla=$CLJS.oh.j($CLJS.Lg,$CLJS.Cm.h($CLJS.Fm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tz,null,$CLJS.uz,null],null),null)),$CLJS.Zm.A()),$CLJS.qg.h(SV,QV));nla=$CLJS.si(QV);$CLJS.kL(fla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid time bucketing unit"],null)],null),nla));$CLJS.UV=$CLJS.oh.j($CLJS.Lg,$CLJS.Cm.h($CLJS.Fm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tz,null,$CLJS.uz,null],null),null)),$CLJS.Zm.A()),$CLJS.qg.l(SV,NV,$CLJS.G([QV,LV])));
VV=$CLJS.si($CLJS.UV);$CLJS.kL($CLJS.FV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid datetime bucketing unit"],null)],null),VV));var ola=$CLJS.kf.h(VV,$CLJS.cn);$CLJS.kL($CLJS.JV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid temporal bucketing unit"],null)],null),ola));var pla=$CLJS.zz.h(OV,TV);
$CLJS.kL($CLJS.GV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid datetime truncation unit"],null)],null),pla));$CLJS.WV=$CLJS.zz.h(MV,RV);$CLJS.kL(ela,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid datetime extraction unit"],null)],null),$CLJS.WV));var XV=$CLJS.kf.h(OV,$CLJS.Jj);$CLJS.kL($CLJS.KV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid date interval unit"],null)],null),XV));
$CLJS.kL($CLJS.oL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid time interval unit"],null)],null),TV));var qla=$CLJS.zz.h(XV,TV);$CLJS.kL($CLJS.pL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.ev,"Valid datetime interval unit"],null)],null),qla));
$CLJS.kL(jla,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.IV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dn,$CLJS.JV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cn,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null)],null));