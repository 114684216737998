var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var nX,oX,wma,xma,yma,qX,rX,sX,tX,vX,zma,xX;nX=function(a){switch(arguments.length){case 2:return $CLJS.qV(arguments[0],arguments[1]);case 3:return $CLJS.pV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};oX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};wma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
xma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.pX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);yma=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);qX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
rX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);sX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);tX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.uX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
vX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);zma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.wX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);xX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.yX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.DL.o(null,$CLJS.HG,function(){return $CLJS.hl});$CLJS.kL(xX,$CLJS.gk);$CLJS.DL.o(null,$CLJS.NG,function(){return $CLJS.sl});$CLJS.kL(xX,$CLJS.gk);$CLJS.kL(zma,$CLJS.St);$CLJS.DL.o(null,$CLJS.wG,function(){return $CLJS.cl});$CLJS.kL(xma,$CLJS.Rt);$CLJS.DL.o(null,$CLJS.uG,function(){return $CLJS.LK});$CLJS.kL(yma,$CLJS.Gl);
var Ama=[":\\d{2}",oX($CLJS.G(["\\.\\d{1,6}"]))].join(""),zX=["\\d{2}:\\d{2}",oX($CLJS.G([Ama]))].join(""),AX=["\\d{4}-\\d{2}-\\d{2}T",zX].join(""),BX=["(?:Z|(?:[+-]",zX,"))"].join(""),Bma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Cma=$CLJS.Fi(["^",zX,"$"].join("")),Dma=$CLJS.Fi(["^",zX,BX,"$"].join("")),Ema=$CLJS.Fi(["^",AX,"$"].join("")),Fma=$CLJS.Fi(["^",AX,BX,"$"].join(""));$CLJS.kL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"date string literal"],null),Bma],null));
$CLJS.kL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"local time string literal"],null),Cma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"offset time string literal"],null),Dma],null)],null));
$CLJS.kL(tX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"local date time string literal"],null),Ema],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"offset date time string literal"],null),Fma],null)],null));
$CLJS.DL.o(null,$CLJS.vG,function(a){return $CLJS.m($CLJS.qV?$CLJS.qV(tX,a):nX.call(null,tX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Kl,null,$CLJS.TJ,null],null),null):$CLJS.m($CLJS.qV?$CLJS.qV(sX,a):nX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.PI,null,$CLJS.Kl,null],null),null):$CLJS.m($CLJS.qV?$CLJS.qV(rX,a):nX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.YK,null,$CLJS.Kl,null],null),null):$CLJS.Kl});$CLJS.kL($CLJS.uX,sX);$CLJS.kL(qX,rX);
$CLJS.kL($CLJS.yX,tX);$CLJS.kL(wma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wk,$CLJS.uX,qX,$CLJS.yX],null));$CLJS.CX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.kL($CLJS.wX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"year-month string literal"],null),$CLJS.CX],null));$CLJS.DX=$CLJS.Fi("^\\d{4}$");$CLJS.kL($CLJS.pX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.ev,"year string literal"],null),$CLJS.DX],null));
$CLJS.kL(vX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hk,$CLJS.AL],null)],null)],null));$CLJS.vV($CLJS.kl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.ev,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.kl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,vX],null),$CLJS.Lb],null));