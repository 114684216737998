var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var tZ,uZ,yna,yZ,zZ,Ana,AZ,BZ,DZ,EZ,FZ,GZ,HZ,JZ,KZ,LZ,Bna,OZ,PZ,zna;
tZ=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lg,a],null)],null);for(var c=$CLJS.Lg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,y,B,H,I){return function(Q){return $CLJS.oh.j(v,$CLJS.rg.g(function(Y,aa,ha,qa,Ea){return function(jb){var lb=$CLJS.J(jb,0,null);jb=$CLJS.J(jb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kf.h(Ea,lb),jb],null)}}(q,u,v,x,y,B,H,I)),Q)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.kf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.dg($CLJS.Lm,g)):n}else return c}};uZ=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Ie(b,$CLJS.M.h(c,2))};$CLJS.vZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Cm.h($CLJS.OL,$CLJS.Zd)),$CLJS.RQ.g(a));return tZ($CLJS.um.l(a,$CLJS.XO,$CLJS.G([$CLJS.oZ])),function(c){return uZ($CLJS.BG,b,c)})};
$CLJS.wZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Cm.h($CLJS.JG,$CLJS.Zd)),$CLJS.PP.g(a));return tZ($CLJS.um.l(a,$CLJS.XO,$CLJS.G([$CLJS.oZ])),function(c){return uZ($CLJS.PP,b,c)})};yna=function(a){function b(d){return $CLJS.sg(c,$CLJS.G([$CLJS.XO.g(d)]))}function c(d){return $CLJS.nf($CLJS.yP.g(d),$CLJS.sg(b,$CLJS.G([$CLJS.hZ.g(d)])))}return $CLJS.m($CLJS.xZ.g(a))?$CLJS.ag(!0):$CLJS.si(b(a))};
yZ=function(a){for(var b=$CLJS.ag(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),y=$CLJS.D(v),B=x,H=y,I=$CLJS.Em.h(n,yna(B));f=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(Q,Y,aa,ha,qa,Ea){return function Mb(lb,Fb){try{if($CLJS.qe(Fb)&&3===$CLJS.E(Fb))try{var ac=$CLJS.Td(Fb,0);if($CLJS.O(ac,$CLJS.GQ))try{var Tb=$CLJS.Td(Fb,1);if($CLJS.m($CLJS.oQ.g(Tb)))try{var Ub=$CLJS.Td(Fb,1);if(null!=Ub?Ub.C&256||$CLJS.t===Ub.bg||(Ub.C?0:$CLJS.Nb($CLJS.lc,Ub)):
$CLJS.Nb($CLJS.lc,Ub))try{var ge=$CLJS.M.j(Ub,$CLJS.oQ,$CLJS.bQ);if($CLJS.m($CLJS.$f(ha)(ge))){var aA=$CLJS.M.h(Ub,$CLJS.oQ);$CLJS.Td(Fb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Ea),": no join named ",$CLJS.aj.l($CLJS.G([aA]))].join("")],null)}throw $CLJS.Z;}catch(Og){if(Og instanceof Error){var tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;
}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)throw $CLJS.Z;throw tj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){tj=Og;if(tj===$CLJS.Z)return $CLJS.sZ(Mb,lb,Fb);throw tj;}throw Og;}}}(f,g,l,I,n,q,u,v,x,y,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Lg,$CLJS.um.l(B,$CLJS.XO,$CLJS.G([zna]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;else return null}};
zZ=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);Ana=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);AZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);BZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.CZ=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);
DZ=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);EZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);FZ=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);GZ=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);
HZ=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.IZ=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);JZ=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);KZ=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);LZ=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);
$CLJS.MZ=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.xZ=new $CLJS.N(null,"source-card","source-card",-1580820390);Bna=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.NZ=new $CLJS.N(null,"filters","filters",974726919);OZ=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);PZ=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.QZ=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);zna=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.kL(FZ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.IZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.WQ,$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.el,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gl,$CLJS.Lb],null)],null)],null));$CLJS.kL(zZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.EV],null)],null));
$CLJS.kL(KZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.EV],null)],null));$CLJS.kL(JZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gl,new $CLJS.k(null,1,[$CLJS.lk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null));
var RZ=$CLJS.Em.h(function(a){a=$CLJS.C($CLJS.vZ(a));return $CLJS.m(a)?["Invalid :expression reference: no expression named ",$CLJS.aj.l($CLJS.G([$CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null))]))].join(""):null},function(a){a=$CLJS.C($CLJS.wZ(a));return $CLJS.m(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.g($CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null)))].join(""):null});
$CLJS.kL(LZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ev,"Valid references for a single query stage",$CLJS.gv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.kl);return RZ(a)}],null),$CLJS.$f(RZ)],null));
$CLJS.kL($CLJS.QZ,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.CZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.fZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.JL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.IP,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),zZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PP,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.mW],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),KZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NZ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),JZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aS,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.lZ],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.hV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xZ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.iV],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ie(a,$CLJS.nR)}],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.Dm.h($CLJS.aQ,$CLJS.xZ))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,LZ],null)],null));$CLJS.kL(AZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aQ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.hV],null)],null)],null)],null));
$CLJS.kL(BZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.iV],null)],null)],null)],null));$CLJS.kL(EZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,AZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,BZ],null)],null));
$CLJS.kL(GZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.Em.h($CLJS.aQ,$CLJS.xZ))],null)],null));$CLJS.kL(HZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,$CLJS.IZ,$CLJS.CZ],null));
$CLJS.kL(Ana,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,HZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ot,new $CLJS.k(null,1,[$CLJS.rk,$CLJS.wz],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,FZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QZ],null)],null)],null)],null));
$CLJS.kL(PZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,HZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ot,new $CLJS.k(null,1,[$CLJS.rk,$CLJS.wz],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,FZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,EZ],null)],null)],null)],null));$CLJS.kL(DZ,GZ);
$CLJS.kL(OZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ev,"Valid references for all query stages",$CLJS.gv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.kl);return yZ.g?yZ.g(a):yZ.call(null,a)}],null),$CLJS.$f(yZ)],null));
$CLJS.kL($CLJS.dZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,PZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,DZ],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,OZ],null)],null));
$CLJS.kL(Bna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.MZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,$CLJS.jV,$CLJS.kV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.dZ],null)],null)],null),$CLJS.xna],null));