var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var wqa,b3,xqa,f3,g3,x3,yqa,zqa,B3,q3,D3,Aqa,s3;wqa=function(a){return $CLJS.A(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};b3=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ie($CLJS.zfa,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
xqa=function(a){var b=$CLJS.Qf(new $CLJS.k(null,1,[$CLJS.GG,!0],null)),c=$CLJS.M.h(b,$CLJS.yfa),d=$CLJS.M.h(b,$CLJS.GG);return $CLJS.A(a)?(b=$CLJS.cv.g(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v);x=b3(x,$CLJS.Gb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}u=$CLJS.C(l);return $CLJS.nf(b3(u,
$CLJS.Gb(d)),g($CLJS.zd(l)))}return null}},null,null)}(wqa($CLJS.lG(a)))}()),$CLJS.m(c)?$CLJS.cv.g($CLJS.jg(c,b)):b):null};$CLJS.c3=function(a,b){var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.zj($CLJS.VH("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.k(null,1,[$CLJS.Moa,$CLJS.E(a)],null));return c};$CLJS.d3=function(a,b){a=$CLJS.c0(a);a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);return $CLJS.M.h($CLJS.Mg(a),$CLJS.c3(a,b))};
$CLJS.e3=function(a,b,c,d){a=$CLJS.c0(a);a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.hZ);b=$CLJS.c3(e,b);c=$CLJS.R.R($CLJS.Hm,$CLJS.Mg(e),b,c,d);return $CLJS.U.j(a,$CLJS.hZ,c)};f3=function(a,b){if(null!=a&&null!=a.qh)a=a.qh(a,b);else{var c=f3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=f3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.table",a);}return a};
g3=function(a,b){if(null!=a&&null!=a.jh)a=a.jh(a,b);else{var c=g3[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=g3._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.card",a);}return a};$CLJS.h3=function(a){return $CLJS.N2(a)?a:$CLJS.nZ.g(a)};$CLJS.i3=function(a,b){return f3($CLJS.h3(a),b)};$CLJS.j3=function(a,b){return g3($CLJS.h3(a),b)};$CLJS.l3=function(a,b,c){return $CLJS.k3.j(a,b,c)};
x3=function(a,b,c){var d=$CLJS.l3(a,b,c);return $CLJS.Xm.l($CLJS.G([$CLJS.li(d,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,$CLJS.UI,$CLJS.Cl],null)),function(){var e=$CLJS.m3.D(a,b,c,$CLJS.n3);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.o3,e],null):null}(),function(){var e=$CLJS.Em.h($CLJS.hk,$CLJS.EG)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.hk,e],null):null}(),function(){var e=$CLJS.j0.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.k0,$CLJS.p3.j(a,b,$CLJS.Ae(e)?$CLJS.i3(a,e):"string"===typeof e?
$CLJS.j3(a,$CLJS.d0(e)):null)],null):null}(),function(){var e=$CLJS.e0.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[q3,$CLJS.F.h(e,$CLJS.P2),$CLJS.r3,$CLJS.F.h(e,$CLJS.T2),s3,$CLJS.F.h(e,$CLJS.R2),$CLJS.t3,$CLJS.F.h(e,$CLJS.S2)],null):null}(),function(){var e=$CLJS.V2.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.u3,e],null):null}(),$CLJS.li(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.v3,$CLJS.w3],null))]))};$CLJS.y3=new $CLJS.N(null,"metadata-fn","metadata-fn",915781389);
$CLJS.z3=new $CLJS.N(null,"direction","direction",-633359395);$CLJS.t3=new $CLJS.N(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.v3=new $CLJS.N(null,"breakout-position","breakout-position",-760153191);$CLJS.A3=new $CLJS.N(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.u3=new $CLJS.N(null,"selected","selected",574897764);yqa=new $CLJS.N(null,"is-order-by-column","is-order-by-column",1320802549);
zqa=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);B3=new $CLJS.N("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.C3=new $CLJS.N(null,"include-joined?","include-joined?",-410632824);q3=new $CLJS.N(null,"is-from-previous-stage","is-from-previous-stage",956780376);D3=new $CLJS.N(null,"is-source-table","is-source-table",1559075056);
$CLJS.E3=new $CLJS.N(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.r3=new $CLJS.N(null,"is-from-join","is-from-join",-1107917905);$CLJS.F3=new $CLJS.N(null,"requires-column","requires-column",934105295);$CLJS.w3=new $CLJS.N(null,"order-by-position","order-by-position",-1307229997);Aqa=new $CLJS.N(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.o3=new $CLJS.N(null,"long-display-name","long-display-name",1550784464);$CLJS.n3=new $CLJS.N(null,"long","long",-171452093);
$CLJS.G3=new $CLJS.N(null,"short-name","short-name",-1767085022);$CLJS.H3=new $CLJS.N("lib","source-uuid","lib/source-uuid",1828897581);$CLJS.I3=new $CLJS.N(null,"include-expressions?","include-expressions?",-1817639288);s3=new $CLJS.N(null,"is-calculated","is-calculated",-86687269);$CLJS.J3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();
$CLJS.K3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();
$CLJS.m3=function m3(a){switch(arguments.length){case 2:return m3.h(arguments[0],arguments[1]);case 3:return m3.j(arguments[0],arguments[1],arguments[2]);case 4:return m3.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Ra("metabase.lib.metadata.calculation.display_name",$CLJS.m3);$CLJS.m3.h=function(a,b){return $CLJS.m3.j(a,-1,b)};$CLJS.m3.j=function(a,b,c){return $CLJS.m3.D(a,b,c,$CLJS.cn)};
$CLJS.m3.D=function(a,b,c,d){var e=$CLJS.UI.g($CLJS.FH(c));if($CLJS.m(e))return e;try{return $CLJS.J3.D(a,b,c,d)}catch(f){if(f instanceof Error)throw b=f,$CLJS.yj($CLJS.VH("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.TZ(b)])),new $CLJS.k(null,2,[$CLJS.xO,a,$CLJS.KG,c],null),b);throw f;}};$CLJS.m3.v=4;
$CLJS.L3=function L3(a){switch(arguments.length){case 2:return L3.h(arguments[0],arguments[1]);case 3:return L3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.L3.h=function(a,b){return $CLJS.L3.j(a,-1,b)};
$CLJS.L3.j=function(a,b,c){var d=$CLJS.V.g($CLJS.FH(c));if($CLJS.m(d))return d;try{return $CLJS.K3.j(a,b,c)}catch(e){if(e instanceof Error)throw d=e,$CLJS.yj($CLJS.VH("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),$CLJS.TZ(d)])),new $CLJS.k(null,3,[$CLJS.KG,c,$CLJS.xO,a,$CLJS.h0,b],null),d);throw e;}};$CLJS.L3.v=3;
$CLJS.J3.o(null,$CLJS.cn,function(a,b,c){a=$CLJS.ZF($CLJS.ZD);$CLJS.m($CLJS.fG("metabase.lib.metadata.calculation",a))&&$CLJS.gG("metabase.lib.metadata.calculation",a,$CLJS.W_("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.aj.l($CLJS.G([c])),zqa,$CLJS.DH(c)])),null);return $CLJS.qe(c)&&$CLJS.C(c)instanceof $CLJS.N?$CLJS.ui($CLJS.C(c)):$CLJS.aj.l($CLJS.G([c]))});$CLJS.K3.o(null,$CLJS.cn,function(a,b,c){return xqa($CLJS.Ou($CLJS.m3.j(a,b,c),/[\(\)]/,""))});
$CLJS.M3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},$CLJS.cn,e,a,b,c,d)}();
$CLJS.N3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();
$CLJS.O3=function O3(a){switch(arguments.length){case 2:return O3.h(arguments[0],arguments[1]);case 3:return O3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.O3.h=function(a,b){return $CLJS.O3.j(a,-1,b)};
$CLJS.O3.j=function(a,b,c){var d=$CLJS.FH(c);d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.SQ),f=$CLJS.hk.g(d);if($CLJS.m(f))return f;e=$CLJS.m($CLJS.m(e)?$CLJS.Ie($CLJS.WV,e):e)?$CLJS.cl:null;if($CLJS.m(e))return e;d=$CLJS.EG.g(d);if($CLJS.m(d))return d;a=$CLJS.N3.j(a,b,c);return $CLJS.PH(a,$CLJS.hl)?a:$CLJS.hl};$CLJS.O3.v=3;$CLJS.N3.o(null,$CLJS.cn,function(a,b,c){return $CLJS.EL(c)});
$CLJS.N3.o(null,$CLJS.QL,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.O3.j(a,b,c)});$CLJS.N3.o(null,$CLJS.HX,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);return"string"===typeof d?$CLJS.EL(c):$CLJS.O3.j(a,b,d)});
$CLJS.k3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();
$CLJS.k3.o(null,$CLJS.cn,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.wz,$CLJS.iW,$CLJS.EG,$CLJS.O3.j(a,b,c),$CLJS.V,$CLJS.L3.j(a,b,c),$CLJS.UI,$CLJS.m3.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.VH("Error calculating metadata {0}",$CLJS.G([$CLJS.TZ(d)])),new $CLJS.k(null,3,[$CLJS.xO,a,$CLJS.h0,b,$CLJS.KG,c],null),d);}throw e;}});
$CLJS.P3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();
$CLJS.kL(B3,new $CLJS.S(null,19,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UI,$CLJS.Gl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.o3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.Gl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.k0,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,B3],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[q3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.r3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[s3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.t3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[D3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[Aqa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[yqa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.gk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.Gl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pW,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.Gl],null),
new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XG,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.Gl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.G3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.Gl],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.F3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.u3,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cn,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),$CLJS.gk],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.z3,
new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,$CLJS.GJ,$CLJS.tJ],null)],null)],null));$CLJS.p3=function p3(a){switch(arguments.length){case 2:return p3.h(arguments[0],arguments[1]);case 3:return p3.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.p3.h=function(a,b){return $CLJS.p3.j(a,-1,b)};
$CLJS.p3.j=function(a,b,c){try{return $CLJS.P3.j(a,b,c)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.yj($CLJS.VH("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.DH(c),$CLJS.TZ(d)])),new $CLJS.k(null,3,[$CLJS.xO,a,$CLJS.h0,b,$CLJS.KG,c],null),d);}throw e;}};$CLJS.p3.v=3;$CLJS.P3.o(null,$CLJS.cn,function(a,b,c){return x3(a,b,c)});
$CLJS.P3.o(null,$CLJS.g0,function(a,b,c){return $CLJS.Xm.l($CLJS.G([x3(a,b,c),new $CLJS.k(null,1,[D3,$CLJS.F.h($CLJS.aQ.g($CLJS.C($CLJS.hZ.g(a))),$CLJS.Bk.g(c))],null)]))});
$CLJS.Q3=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Lk,$CLJS.EH],null),$CLJS.Lk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.DH(l)},$CLJS.cn,e,a,b,c,d)}();$CLJS.Q3.o(null,$CLJS.HG,function(){return $CLJS.Lg});module.exports={display_name:$CLJS.m3};