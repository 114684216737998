var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var EX,FX,GX,IX,JX,KX,LX;EX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);FX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
GX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.HX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);IX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
JX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);KX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);LX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.zV($CLJS.jJ,$CLJS.G([$CLJS.yu,$CLJS.mJ,$CLJS.St,$CLJS.pL]));$CLJS.DL.o(null,$CLJS.HX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.EL(a);return $CLJS.le(a)?(a=$CLJS.Gz.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.PI,null,$CLJS.TJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.oV($CLJS.HX,$CLJS.QL);
for(var MX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eK,$CLJS.MK],null)),NX=null,OX=0,PX=0;;)if(PX<OX){var QX=NX.$(null,PX);$CLJS.zV(QX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null),$CLJS.St,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.pL],null)]));$CLJS.oV(QX,$CLJS.HX);PX+=1}else{var RX=$CLJS.A(MX);if(RX){var SX=RX;if($CLJS.re(SX)){var TX=$CLJS.$c(SX),Gma=$CLJS.ad(SX),Hma=TX,Ima=$CLJS.E(TX);MX=Gma;NX=Hma;OX=Ima}else{var UX=$CLJS.C(SX);$CLJS.zV(UX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.gq,$CLJS.KL],null),$CLJS.St,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.pL],null)]));$CLJS.oV(UX,$CLJS.HX);MX=$CLJS.D(SX);NX=null;OX=0}PX=0}else break}
for(var VX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.FI,$CLJS.ZH,$CLJS.bK,$CLJS.xK,$CLJS.hK,$CLJS.EI,$CLJS.TK],null)),WX=null,XX=0,YX=0;;)if(YX<XX){var Jma=WX.$(null,YX);$CLJS.zV(Jma,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)]));YX+=1}else{var ZX=$CLJS.A(VX);if(ZX){var $X=ZX;if($CLJS.re($X)){var aY=$CLJS.$c($X),Kma=$CLJS.ad($X),Lma=aY,Mma=$CLJS.E(aY);VX=Kma;WX=Lma;XX=Mma}else{var Nma=$CLJS.C($X);$CLJS.zV(Nma,$CLJS.G([$CLJS.yu,
$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)]));VX=$CLJS.D($X);WX=null;XX=0}YX=0}else break}$CLJS.zV($CLJS.iJ,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.GV],null)]));
for(var bY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.TK,null,$CLJS.hK,null,$CLJS.xK,null,$CLJS.kK,null,$CLJS.EI,null,$CLJS.ZH,null,$CLJS.FI,null,$CLJS.bK,null],null),null)),cY=null,dY=0,eY=0;;)if(eY<dY){var Oma=cY.$(null,eY);$CLJS.zV(Oma,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)]));eY+=1}else{var fY=$CLJS.A(bY);if(fY){var gY=fY;if($CLJS.re(gY)){var hY=$CLJS.$c(gY),Pma=$CLJS.ad(gY),Qma=hY,Rma=$CLJS.E(hY);
bY=Pma;cY=Qma;dY=Rma}else{var Sma=$CLJS.C(gY);$CLJS.zV(Sma,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)]));bY=$CLJS.D(gY);cY=null;dY=0}eY=0}else break}$CLJS.kL(LX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ht,$CLJS.wI,$CLJS.rJ,$CLJS.JJ],null));
$CLJS.BV($CLJS.dK,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,LX],null)],null)],null)],null)]));
$CLJS.kL(KX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.vL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.ev,"valid timezone ID",$CLJS.gv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.kl);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.WL.tz.names()))],null));
$CLJS.BV($CLJS.vI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,KX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,KX],null)],null)],null)],null)]));
$CLJS.oV($CLJS.vI,$CLJS.HX);$CLJS.zV($CLJS.EJ,$CLJS.G([$CLJS.yu,$CLJS.XI]));$CLJS.kL(GX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.AL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.PH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PI,$CLJS.TJ],null))}],null)],null));
$CLJS.kL(EX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EG,new $CLJS.k(null,1,[$CLJS.Qj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,GX],null)],null)],null)],null));
$CLJS.vV($CLJS.GI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.ev,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.GI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,EX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.ev,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.uX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.wX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.pX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.cn],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.HV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.yt,new $CLJS.k(null,1,[$CLJS.ev,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.oB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.yX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.cn],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.FV],null)],null)],null)],null)],null)],null));
$CLJS.DL.o(null,$CLJS.GI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.oB)?$CLJS.F.h(a,$CLJS.cn)?$CLJS.TJ:$CLJS.m($CLJS.qV($CLJS.HV,a))?$CLJS.PI:$CLJS.TJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.CX,b))?$CLJS.PI:$CLJS.m($CLJS.Di($CLJS.DX,b))?$CLJS.PI:null:null;if($CLJS.m(a))return a;b=$CLJS.EL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.PH(c,$CLJS.Dl)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.kL(JX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,$CLJS.oB],null),$CLJS.St],null));
$CLJS.BV($CLJS.sJ,$CLJS.G([$CLJS.yu,$CLJS.TJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,JX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.pL],null)],null)],null)],null)]));
$CLJS.zV($CLJS.$D,$CLJS.G([$CLJS.yu,$CLJS.YK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.oL],null)]));$CLJS.kL(FX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Ht,$CLJS.gJ,$CLJS.fn,$CLJS.kn,$CLJS.YI,$CLJS.VK,$CLJS.$K,$CLJS.jn,$CLJS.gn,$CLJS.en,$CLJS.hn,$CLJS.JI],null));$CLJS.kL(IX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ht,$CLJS.wI,$CLJS.rJ,$CLJS.JJ],null));
$CLJS.BV($CLJS.pK,$CLJS.G([$CLJS.yu,$CLJS.cl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,FX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ak,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,IX],null)],null)],
null)],null)]));